import React, { createRef, useState, useEffect, useLayoutEffect } from "react";
// import umrahImg from "../../assets/imgs/best-umrah-2023-2024-zamzam-hajj-website.png";
import umrahImg from "../../assets/imgs/ZamZam-banner-2024.png";
import HajjGuides from "../HajjGuides";
import CommonServices from "../CommonServices";
import UmrahPackage from "../UmrahPackage";
import Requirements from "../Requirements";
import Subscribe from "../Subscribe";
import SponsorQuran from "../SponsorQuran";
import AboutUs from "../AboutUs";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import Banner from "../Banner";
import HajjReading from "../HajjReading";
import ZamZamVideosSection from "../ZamZamVideosSection";
import ViesGallerySection from "../ViesGallerySection";
import PricingTableSection from "../PricingTableSection";
import UpcomingEventSection from "../UpcomingEventSection";
import TestimonialsSection from "../TestimonialsSection";
import ContactSection from "../ContactSection";
import Book from "../Book";

import HajjRegistration from "../HajjRegistration";
import WelcomeModal from "../Common/WelcomeModal";
import RequirementsTable from "../Common/Tables/RequirementsTable";

function HomePage(props) {
  const [open, setOpen] = useState(true);
  const homeRef = createRef();
  const aboutRef = createRef();
  const galleryRef = createRef();
  const contactUsRef = createRef();
  const requirementsRef = createRef();
  const hajjPachakgeRef = createRef();
  const commonservicesRef = createRef();
  const bookRef = createRef();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.indexOf("/#home") > -1) {
        homeRef && homeRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.location.href.indexOf("/#aboutUs") > -1) {
        aboutRef && aboutRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.location.href.indexOf("/#gallery") > -1) {
        galleryRef && galleryRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.location.href.indexOf("/#contact-us") > -1) {
        contactUsRef &&
          contactUsRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.location.href.indexOf("/#requirements") > -1) {
        requirementsRef &&
          requirementsRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // else if (window.location.href.indexOf("/#hajj-packages") > -1) {
      //     hajjPachakgeRef && hajjPachakgeRef.current.scrollIntoView({ behavior: "smooth" });
      // }
      else if (window.location.href.indexOf("/#common-services") > -1) {
        commonservicesRef &&
          commonservicesRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.location.href.indexOf("/#books") > -1) {
        bookRef && bookRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, 1000);
  }, [
    homeRef,
    aboutRef,
    galleryRef,
    contactUsRef,
    requirementsRef,
    hajjPachakgeRef,
    commonservicesRef,
    bookRef,
  ]);

  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContainer">
        <div ref={homeRef}>
          <Banner />
          <HajjRegistration
            title="Hajj 2025 Registration"
            background="#008000"
            buttonTitle="Registration"
            buttonUrl="https://docs.google.com/forms/d/e/1FAIpQLScdQ4gR5wDzrLPNBbVXe0PNf7pMLodrIkNjomxmL-ziEKbPTw/viewform"
          />
          <HajjRegistration
            title="New Umrah packages for 2025 Registration"
            buttonUrl="https://www.facebook.com/ZamzamHajjUSA/"
          />
        </div>
        {/* Registration section  is hiding for now */}
        <HajjGuides />
        <HajjReading />
        {/* <ZamZamVideosSection /> */}
        <div ref={galleryRef}>
          <ViesGallerySection />
        </div>
        {/* ---------  HAJJ 2023 PACKAGES section is hiding ---------*/}
        {/* <div ref={hajjPachakgeRef}>
          <PricingTableSection />
        </div> */}
        {/* <UpcomingEventSection /> */}
        {/* ------- this is section is hidden also --------------- */}
        {/* <div ref={commonservicesRef}>
          <CommonServices />
        </div> */}
        <div className="SectionContainer umragImgContainer">
          <a href="https://www.facebook.com/ZamzamHajjUSA/" target="blank">
            <img
              src={
                "https://zamzamhajj-media.s3.us-east-1.amazonaws.com/zamzam-website-banner-2025.png"
              }
              alt="UMRAH 2025 PACKAGES"
            />
          </a>
        </div>
        <div className="SectionContainer">
          <RequirementsTable />
        </div>
        <UmrahPackage />
        <TestimonialsSection />
        <div ref={requirementsRef}>
          <Requirements />
        </div>
        <div ref={aboutRef}>
          <AboutUs />
        </div>
        <div ref={bookRef}>
          <Book />
        </div>
        <SponsorQuran />
        <div ref={contactUsRef}>
          <ContactSection />
        </div>
        {/* <Subscribe /> */}
      </div>
      <FooterSection />
      {/* ======== */}
      {/* <WelcomeModal open={open} handleClose={() => setOpen(false)} /> */}
    </div>
  );
}

export default HomePage;
