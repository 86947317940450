import React from "react";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import { PageTitleOne } from "master-react-lib";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import backgroundPattern from "../../assets/imgs/PagePattern.svg";

const UmrahPage2025 = () => {
  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContainer">
        <PageTitleOne
          Title="Umrah 2025 Packages"
          SubTitle="ZamZam Hajj & Umrah"
          color="#fff"
          backgroundcolor="#56B149"
          backgroundPattern={backgroundPattern}
        />
        <Grid container style={{ padding: "16px 8px 8px 8px" }} spacing={1}>
          <Grid item xs={12} md={6}>
            <div className="umrahImageContainer">
              <img
                src="https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-post-card-with-qr-2025.png"
                alt="zamzam 2025 package"
              />
              <Button
                variant="contained"
                href="tel:19175407944"
                style={{
                  padding: "10px 20px",
                  width: "100%",
                  color: "#fff",
                  background: "#ff6300",
                  fontWeight: 700,
                }}
              >
                call to book
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="umrahImageContainer">
              <img
                src="https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-back-2025.png"
                alt="zamzam 2025 package"
              />
              <Button
                variant="contained"
                href="tel:19175407944"
                style={{
                  padding: "10px 20px",
                  width: "100%",
                  color: "#fff",
                  background: "#ff6300",
                  fontWeight: 700,
                }}
              >
                call to book
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <FooterSection />
    </div>
  );
};

export default UmrahPage2025;
