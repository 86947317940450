import React, { Component } from "react";

function HajjRegistration(props) {
  return (
    <div
      className="HajjRegistration"
      style={{ background: props.background ? props.background : "" }}
    >
      <h2>{props.title}</h2>
      {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoo-L-PYKGFgypidoRgh25POqvVNoMEfVevRRP_67B46TnrA/viewform" target="_blank">REGISTER</a> */}
      <a href={props.buttonUrl} target="_blank">
        {props.buttonTitle ? props.buttonTitle : "View Details"}
      </a>
    </div>
  );
}

export default HajjRegistration;
