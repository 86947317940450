import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import zamzamLogo from "../assets/imgs/zamzamlogo.png";

import "../assets/css/style.css";

class SponsorQuran extends Component {
  render() {
    return (
      <div className="SponsorQuranContainer">
        <div className="SponsorQuranLeftCol SectionContainer">
          <h2>Be part of the reward by sponsoring Al-Quran</h2>
          <p>
            Those who in charity spend of their goods by night and by day, in
            secret and in public, have their reward with their Lord: on them
            shall be no fear, nor shall they grieve (Al-Quran 2:274)
          </p>

          <Button
            className="SponsorQuranButton"
            variant="contained"
            href="https://give.deenfund.com/alquran-dawah-center"
            target="blank"
          >
            SPONSOR QURAN FOR $1 <ArrowForwardIosRoundedIcon />
          </Button>
          <div className="powerdByCompanyText">
            Powered by <span>DEENFUND</span>
          </div>
        </div>

        <div className="SponsorQuranRightCol SectionContainer">
          <img src={zamzamLogo} alt="Logo" className="ZamzamLogoSmaill" />
          <p>প্রতিশ্রুতি অনুযায়ী সেবা।</p>
          <p>
            The Prophet (ﷺ) said, “(The performance of) ‘Umrah during Ramadan is
            equal to Hajj (pilgrimage).” Or said, “Equal to the performance of
            Hajj with me.” [Al-Bukhari and Muslim].
          </p>

          {/* <Button className="ButtonFIlledGreen" variant="contained" color="primary" href="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf" target="blank"> Hajj registration</Button> */}
          <Button
            className="ButtonFIlledGreen"
            variant="contained"
            color="primary"
            href="https://www.facebook.com/ZamzamHajjUSA/"
            target="blank"
          >
            UMRAH 2025 PACKAGES
          </Button>
        </div>
      </div>
    );
  }
}

export default SponsorQuran;
