import React, { Component } from "react";
import { HeaderWebsite } from "master-react-lib";
import Logo from "../../assets/imgs/ZamZam_Hajj_Umrah_Logo.png";

const headerItems = [
  {
    title: "HOME",
    link: "/#home",
  },

  {
    title: "ABOUT US",
    link: "/#aboutUs",
  },

  // {
  //   title: "Hajj Packages",
  //   link: "/#hajj-packages",
  // },
  // {
  //   title: "UMRAH 2024",
  //   path: "/",
  //   link: "/umrah-2024",
  // },
  {
    title: "UMRAH 2025",
    path: "/",
    // link: "https://zamzamhajj.com/flyers/ZamZam-Umrah-2023-February-Ramadhan2023.png",
    link: "/umrah-2025",
  },

  {
    title: "Registration",
    path: "https://docs.google.com/forms/d/e/1FAIpQLScdQ4gR5wDzrLPNBbVXe0PNf7pMLodrIkNjomxmL-ziEKbPTw/viewform",
    children: [
      {
        title: "Hajj 2025 registration",
        link: "https://docs.google.com/forms/d/e/1FAIpQLScdQ4gR5wDzrLPNBbVXe0PNf7pMLodrIkNjomxmL-ziEKbPTw/viewform",
      },

      {
        // title: "Download UMRAH registration form",
        // link: "https://zamzamhajj-media.s3.amazonaws.com/best-umrah-2023-2024-zamzam-hajj.png",
        title: "UMRAH 2025 PACKAGES",
        link: "https://www.facebook.com/ZamzamHajjUSA/",
      },

      // {
      //   title: "Requirements for hajj",
      //   path: "/",
      // },

      // {
      //   title: "Checklist for hajj",
      //   path: "/",
      // },

      // {
      //   title: "Hajj Registration form - print ",
      //   path: "/",
      // },
    ],
  },

  {
    title: "Reads",
    link: "/reads",
  },
  {
    title: "Gallery",
    link: "/#gallery",
  },

  {
    title: "Contact us",
    //path: "/",
    link: "/#contact-us",
  },
];

const onLogoClick = () => {
  console.log("logoClicked");
};

const navigate = (path) => {
  console.log(path);
};

const topHeaderButton = {
  // buttonText: "DOWNLOAD HAJJ REGISTRATION FORM",
  buttonText: "UMRAH 2025 PACKAGES",
};

class HeaderSection extends Component {
  render() {
    return (
      <div id="home">
        <HeaderWebsite
          logo={Logo}
          logolink="/#home"
          headerItems={headerItems}
          navigate={navigate}
          headerSignupButton={true}
          email1={"yeszamzam@gmail.com"}
          ContactNumber={"+1 (917) 540-7944"}
          topHeaderButton={topHeaderButton}
          // ButtonLink="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"
          ButtonLink="https://www.facebook.com/ZamzamHajjUSA/"
          //target="blank"
        />
      </div>
    );
  }
}

export default HeaderSection;
